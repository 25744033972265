/* Helpers */
body {
    overflow: auto !important;
    background-color: #ffffff;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}

.sidenav .divider {
    margin: 1rem 0 1rem 0;
}

.text-center {
    text-align: center;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.h3 {
    font-size: 2.92rem;
    line-height: 110%;
    margin: 1.9466666667rem 0 1.168rem 0;
}

.m0 {
    margin: 0;
}

.mr1 {
    margin-right: .5rem;
}

.mr2 {
    margin-right: 1rem !important;
}

.mr4 {
    margin-right: 3rem;
}

.ml0 {
    margin-left: 0 !important;
}

.ml1 {
    margin-left: .5rem;
}

.ml2 {
    margin-left: 1rem;
}

.ml4 {
    margin-left: 3rem;
}

.mt0 {
    margin-top: 0rem;
}

.mt2 {
    margin-top: 1rem;
}

.mt4 {
    margin-top: 3rem;
}

.mb2 {
    margin-bottom: 1rem;
}

.mb4 {
    margin-bottom: 3rem;
}

.p3 {
    padding: 2rem;
}

.p4 {
    padding: 3rem;
}

.pb2 {
    padding-bottom: 1rem;
}

.pb4 {
    padding-bottom: 3rem;
}

.pl0 {
    padding-left: 0;
}

.pl1 {
    padding-left: .5rem;
}

.pl2 {
    padding-left: 1rem;
}

.pr1 {
    padding-right: .5rem;
}

.pr2 {
    padding-right: 1rem;
}

.pr3 {
    padding-right: 2rem;
}

.pl3 {
    padding-left: 2rem;
}

.pt2 {
    padding-top: 1rem !important;
}

.pt4 {
    padding-top: 3rem !important;
}

.border {
    border: 1px solid #000000;
}

.font-1 {
    font-size: 1rem;
}

.d-flex {
    display: flex;
}

.d-block {
    display: block;
}

.d-inline-block {
    display: inline-block;
}

/* Tabs */
.tabs.tabs-fixed-width {
    overflow-x: hidden;
}

.tabs .tab a {
    color: #212121;
}

.tabs .tab a:hover,
.tabs .tab a.active {
    color: #212121;
}

.tabs .tab a:focus.active {
    color: #212121;
    background-color: #eee;
}

.tabs .indicator {
    background-color: #212121;
}

.btn {
    border-radius: 25px;
}

.btn.black.disabled {
    background-color: #ffffff !important;
    color: #000000 !important;
    border: 1px solid #000000;
}

.btn-link {
    background: none !important;
    border: none;
    padding: 0 !important;
    /*optional*/
    font-family: arial, sans-serif;
    /*input has OS specific font-family*/
    color: #069;
    text-decoration: underline;
    cursor: pointer;
}

.header-with-button {
    line-height: 32.4px;
}

/* Forms */
.form-errors {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    border-left: 3px solid #f44336;
}

.form-warnings {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    border-left: 3px solid #ff9800;
}

.form-messages {
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 1rem 0;
    border-left: 3px solid #4caf50;
}

/* Loader */
.spinner-layer {
    border-color: #333;
}

/* Logo in navbar */
.nav-wrapper .brand-logo img {
    height: 40px;
    margin-top: 12px;
}

.nav-wrapper .brand-logo {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}

.sidenav img {
    height: 100%;
    margin: auto;
    display: block;
}

img.inverted {
    -webkit-filter: invert(1);
    filter: invert(1);
}

@media only screen and (min-width: 993px) {
    nav a.sidenav-trigger {
        display: block;
    }
}

/* Tables */
table thead th {
    text-align: center;
}

table .table-sort-icon i.material-icons {
    letter-spacing: -5px;
}

table thead th .material-icons {
    vertical-align: middle;
}

.table-pagination {
    display: flex;
    align-items: center;
}

.flex-auto {
    flex: auto;
}

/* Switch */
.switch label .lever:before {
    background-color: #000000;
}

.switch label input[type=checkbox]:checked+.lever {
    background-color: #4caf50;
}

.switch label input[type=checkbox]:checked+.lever:after {
    background-color: #4caf50;
}

/* High Risk switch */
.high-risk-switch label .lever {
    background-color: #81c784;
}

.high-risk-switch label .lever:before {
    background-color: #f44336;
}

.high-risk-switch label .lever:after {
    background-color: #4caf50;
}

.high-risk-switch label input[type=checkbox]:checked+.lever {
    background-color: #e57373;
}

.high-risk-switch label input[type=checkbox]:checked+.lever:after {
    background-color: #f44336;
}

/* Video */
.video-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    height: 0;
}

.video-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Typography */
.wrap-newlines {
    white-space: pre-line;
}

.unordered-list>li {
    padding-bottom: 1rem;
}

/* Buttons */
.range-button {
    width: 120px;
}

/* Summary charts */
.summary-chart svg {
    display: block;
    margin: auto;
}

/* Modals */
.modal .js-plotly-plot,
.modal .plot-container {
    /* Height of modal - margins - footer */
    height: calc(70vh - 48px - 56px);
    max-width: 100%;
}

.confirm-modal {
    max-width: 500px;
}

.chip {
    white-space: nowrap
}


/* @page { size: A4 } */
@import url('/node_modules/paper-css/paper.min.css');

@media print {
    .page-break-after {
        clear: both;
        page-break-after: always;
    }

    .hide-on-print {
        display: none;
    }
}

.relative {
    position: relative;
}

.out-of-alert-zone {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ef5350;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

/* Datepicker fixes */
.datepicker-controls .select-month input {
    width: 80px;
}

/* Timer */
.timer-container {
    font-size: 2rem;
    line-height: 2rem;
}

.timer-button {
    vertical-align: text-top;
}

/* Alert zones */
.alert-zone {
    /* display: flex; */
    /* flex-direction: row; */
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.alert-zone:hover {
    background-color: #eeeeee;
}

.alert-zone .alert-zone-type {
    font-size: 1.25rem;
}

.alert-zone .col {
    align-self: center;
}

.alert-zone .col {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.alert-zone table {
    border: 1px solid #cccccc;
    border-collapse: separate;
    border-radius: 10px;
}

.alert-zone table tr {
    border: none;
}


/* Hide buttons without hover/active */
.alert-zone .btn {
    display: none;
}

.alert-zone:hover .btn {
    display: inline-block;
}

/* Icons */
i {
    vertical-align: middle;
}

table.responsive {
    display: block;
    overflow-x: auto;
    white-space: nowrap;
}

/* Freeze first column for doctors table in Admin dashboard */
/* .doctors-table table {
    table-layout: fixed;
} */

.doctors-table table th:first-child,
.doctors-table table td:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 997;
    background-color: #ffffff;
}

.patients-table .chip {
    font-size: 8px;
}

/* Changelogs */
.changelogs-container {
    position: relative;
}

.changelogs-dropdown {
    display: none;
    position: absolute;
    width: 450px;
    background: white;
    border: 1px solid #dddddd;
    transform: translate(-75%, 0);
    z-index: 999;
}

.changelogs-dropdown p {
    line-height: 1.5;
}

.changelogs-dropdown .changelog-body {
    font-size: 14px;
    line-height: 1.5;
}

.changelogs-container .changelogs-alert {
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 15px;
    background-color: #ffffff;
    line-height: 14px;
    height: 14px;
    color: #000000;
    border-radius: 50%;
    border-color: #dddddd;
}

/* Reminders */
.badge.time-badge {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    line-height: 1rem;
    height: 2rem;
}